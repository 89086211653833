<style scoped>
iframe {
  width: 100%;
  height: 80vh;
}
</style>

<template>
  <iframe :src="apiurl" frameborder="0"></iframe>
</template>

<script>
import setup from "../../config";

export default {
  name: "galeria",
  data() {
    return {
      apiurl: `${setup.api}/admin/arquivos?token=${setup.token()}`,
    };
  },
};
</script>
